import { defaultPostHeaderForJsonData } from '@@apifront/index';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type {
    ICommunityGroup,
    ILink, IMember, IPublication,
    IPublicationSignal,
    IPublicationStatus, IPublicationView, IReaction,
    ReactionType
} from '@@community/data';
import { membersQuery } from './members';
import { expressionBuilder } from '@inwink/expressions/expressionbuilder';
import { buildFileInputManager } from '@@api/fileapi';

export interface IPublicationQuery extends IPublicationQueryBase {
    channelId?: string;
    pinned?: boolean;
    status?: IPublicationStatus;
    view?: IPublicationView;
    global?: boolean;
    search?: string;
    signaled?: boolean;
}

export interface IPublicationQueryBase {
    continuationToken?: string;
    maxItems?: number;
}

export interface IPublicationUpdate {
    pinned?: boolean;
    status?: IPublicationStatus;
    disableLikes?: boolean;
    disableComments?: boolean;
    text?: string;
    links?: any[];
    isPublic?: boolean;
    allowedMemberships?: string[];
}

export function updatePublication(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    update: IPublicationUpdate,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/update`;
    return requestManager.postJson(url, JSON.stringify(update), defaultPostHeaderForJsonData);
}

export function getPublication(requestManager: ICommunityRequestManager,
    publicationId: string, groupId?: string): Promise<IPublication> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}`;
    return requestManager.getJson(url);
}

export interface IPublicationsResult {
    publications?: IPublication[];
    continuationToken?: string;
    hasMoreItems?: boolean;
}

export function getPublications(requestManager: ICommunityRequestManager,
    query: IPublicationQuery, groupId?: string): Promise<IPublicationsResult> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/feed`;
    return requestManager.postJson(url, JSON.stringify(query), defaultPostHeaderForJsonData);
}

export function countPublications(requestManager: ICommunityRequestManager,
    query: IPublicationQuery, groupId?: string): Promise<{ count: number }> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/count`;
    return requestManager.postJson(url, JSON.stringify(query), defaultPostHeaderForJsonData);
}

export function publicationAssetFileInputManager(requestManager: ICommunityRequestManager, groupId?: string) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const fullBaseUrl = `${baseUrl}${groupParam}/publication-asset`;
    const result = buildFileInputManager({
        postJson: (url, data, headers) => {
            return requestManager.postJson(fullBaseUrl + "/" + url, data, headers);
        },
        getJson: (url) => {
            return requestManager.getJson(fullBaseUrl + "/" + url);
        }
    });
    return result;
}

export interface INewPublication {
    text: string;
    channelId: string;
    isPublic?: boolean;
    allowedMemberships?: string[];
    links: any[];
    contentId?: string;
    contentType?: string;
    assets?: { file: string, thumbnail?: string, assetType: string }[];
}
export function publish(requestManager: ICommunityRequestManager, publication: INewPublication, groupId?: string) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/publish`;

    return requestManager.postJson(url, JSON.stringify(publication), defaultPostHeaderForJsonData);
}

export function deletePublication(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/delete`;

    return requestManager.postJson(url);
}

export interface ISignalsResult {
    signals?: IPublicationSignal[];
    continuationToken?: string;
    hasMoreItems?: boolean;
}

export function getSignals(requestManager: ICommunityRequestManager,
    query: IPublicationQueryBase, publicationId: string, groupId?: string): Promise<ISignalsResult> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/signals`;
    return requestManager.postJson(url, JSON.stringify(query), defaultPostHeaderForJsonData);
}

export function signal(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    text: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl + groupParam}/publication/${publicationId}/signal`;

    return requestManager.postJson(url, JSON.stringify({
        text: text
    }), defaultPostHeaderForJsonData);
}

export function deletePublicationSignals(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/signals/delete`;

    return requestManager.postJson(url);
}

export interface ILikesResult {
    memberId: string;
    member?: IMember;
    likeType?: ReactionType;
}

export function getLikes(
    requestManager: ICommunityRequestManager,
    contentId?: string
): Promise<ILikesResult[]> {
    const url = "community/" + requestManager.communityId + "/publication/" + contentId + "/likes";

    return requestManager.postJson(url).then((res: any) => {
        if (res.status && res.status === 204) {
            return null;
        }

        return res;
    });
}

export interface ILikeUnlikeResult {
    success?: boolean;
    info: { likes?: number, reactions?: IReaction }
}

export function like(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    groupId?: string,
    type?: string
): Promise<ILikeUnlikeResult> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/like?likeType=${type ?? ''}`;

    return requestManager.postJson(url, JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function unlike(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    groupId?: string
): Promise<ILikeUnlikeResult> {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/unlike`;

    return requestManager.postJson(url, JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function getCommentLikes(
    requestManager: ICommunityRequestManager,
    commentId: string,
    groupId?: string,
    publicationId?: string,
    contentId?: string,
    contentType?: string,
) {
    let url;
    const baseUrl = `community/${requestManager.communityId}`;
    if (publicationId) {
        url = `publication/${publicationId}`;
    } else if (contentId && contentType) {
        url = `${contentType}/${contentId}`;
    }
    const requestUrl = `${baseUrl}/${url}/comment/${commentId}/like`;

    return requestManager.postJson(requestUrl).then((res: any) => {
        if (res.status && res.status === 204) {
            return null;
        }

        return res;
    });
}

export function getCommentLike(
    requestManager: ICommunityRequestManager,
    commentId: string,
    groupId?: string,
    publicationId?: string,
    contentId?: string,
    contentType?: string,
) {
    let url;
    const baseUrl = `community/${requestManager.communityId}`;
    let commentType = "comment";
    if (publicationId) {
        url = `publication/${publicationId}`;
    } else if (contentId && contentType) {
        if (contentType === "ForumSubject") {
            commentType = "reply";
        }
        url = `${contentType}/${contentId}`;
    }
    const requestUrl = `${baseUrl}/${url}/${commentType}/${commentId}/like`;

    return requestManager.getJson(requestUrl, {
        groupId: groupId
    }).then((res: any) => {
        if (res.status && res.status === 204) {
            return null;
        }

        return res;
    });
}

export function likeComment(
    requestManager: ICommunityRequestManager,
    commentId: string,
    groupId?: string,
    publicationId?: string,
    contentId?: string,
    contentType?: string

) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    let isForumSubject;
    let contextUrl;
    if (publicationId) {
        contextUrl = `publication/${publicationId}`;
    } else if (contentId && contentType) {
        if (contentType === "ForumSubject") {
            isForumSubject = true;
        }
        contextUrl = `${contentType}/${contentId}`;
    }
    let url = `${baseUrl}${groupParam}/${contextUrl}/comments/like/${commentId}`;
    if (isForumSubject) {
        url = `${baseUrl}${groupParam}/${contextUrl}/reply/${commentId}/like`;
    }

    return requestManager.postJson(url, JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function unlikeComment(
    requestManager: ICommunityRequestManager,
    commentId: string,
    groupId?: string,
    publicationId?: string,
    contentId?: string,
    contentType?: string

) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    let isForumSubject;
    let contextUrl;
    if (publicationId) {
        contextUrl = `publication/${publicationId}`;
    } else if (contentId && contentType) {
        if (contentType === "ForumSubject") {
            isForumSubject = true;
        }
        contextUrl = `${contentType}/${contentId}`;
    }
    let url = `${baseUrl}${groupParam}/${contextUrl}/comments/unlike/${commentId}`;
    if (isForumSubject) {
        url = `${baseUrl}${groupParam}/${contextUrl}/reply/${commentId}/unlike`;
    }

    return requestManager.postJson(url, JSON.stringify({}), defaultPostHeaderForJsonData);
}

export function latestComments(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/lastcomments`;

    return requestManager.getJson<any>(url);
}

export interface ICommentsQuery {
    parentId?: string;
    continuationToken?: string;
}

export function getComments(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    query?: ICommentsQuery,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/comments`;

    return requestManager.postJson<{
        data: any[];
        continuationToken: string;
    }>(url, query ? JSON.stringify(query) : null, defaultPostHeaderForJsonData);
}

export function removeComment(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    commentId: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/comments/remove/${commentId}`;

    return requestManager.postJson<any>(url);
}

export function updateComment(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    commentId: string,
    text: string,
    links: ILink[],
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/comments/update/${commentId}`;

    return requestManager.postJson<any>(url, JSON.stringify({
        text,
        links
    }), defaultPostHeaderForJsonData);
}

export function comment(
    requestManager: ICommunityRequestManager,
    publicationId: string,
    text: string,
    links: ILink[],
    replyTo?: string,
    groupId?: string
) {
    const baseUrl = `community/${requestManager.communityId}`;
    const groupParam = groupId ? `/group/${groupId}` : "";
    const url = `${baseUrl}${groupParam}/publication/${publicationId}/comment`;

    return requestManager.postJson(url, JSON.stringify({
        text,
        links,
        replyTo
    }), defaultPostHeaderForJsonData);
}

export interface IMemberMention {
    id: string;
    display: string;
    member: IMember;
}

export function getMembersToMention(
    requestManager: ICommunityRequestManager,
    query: string,
    callback: any,
    group?: ICommunityGroup
) {
    const splitQuery = query.split(" ");

    let filters = splitQuery.map((q) => {
        return {
            $or: [
                { firstname: { $contains: q } },
                { lastname: { $contains: q } }
            ]
        };
    });

    if (group) {
        const isPrivateGroup = group.registrationMode?.toLocaleLowerCase() === "private";
        if (!isPrivateGroup) {
            const memberAccesses = group.memberAccess.map((ma) => ma.id);
            if (memberAccesses.length) {
                filters = expressionBuilder(e => e.And([
                    a => a.FromFilter(filters),
                    a => a.Or([
                        b => b.WithString('membershipLevelId').In(memberAccesses),
                        // Fix provisoire #51377
                        b => b.WithString('membershipLevel.configuration').Contains("isModerator\":true")
                    ])
                ]));
            }
        } else {
            filters = expressionBuilder(e => e.And([
                a => a.FromFilter(filters),
                a => a.Or([
                    // Fix provisoire #51377
                    b => b.WithString('membershipLevel.configuration').Contains("isModerator\":true")
                ])
            ]));
        }
    }

    membersQuery(
        requestManager, {
            filters,
            orders: [{
                asc: true,
                value: { firstname: {} }
            }],
            page: {
                index: 0,
                size: 10
            }
        } as any,
        group?.id
    ).then((res) => {
        return res.data.map((m) => ({
            id: m.id,
            display: `${m.firstname ?? ""} ${m.lastname ?? ""}`,
            member: m
        } as IMemberMention));
    }).then(callback);
}