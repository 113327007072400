import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';

export function getUrlPreview(requestManager: ICommunityRequestManager, target: string): Promise<{
    url: string;
    title?: string;
    description?: string;
    imageUrl?: string;
    hasData: boolean;
}> {
    return requestManager
        .getJson(`community/${requestManager.communityId}/urlpreview?targetUrl=` + encodeURIComponent(target));
}
